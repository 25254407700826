import { mergeWith } from 'lodash';
import { createReducer } from '@reduxjs/toolkit';
import { replaceArrays } from 'shared/lodash-utils';
import { normalize } from 'normalizr';
import { CollectionCourse } from 'redux/schemas/models/collections';
import {
  CollectionLecturePagesNormalized, CollectionEntities, CollectionFolderSchema,
  CollectionCourseListSchema, CollectionCourseSchema,
} from 'redux/schemas/api/collections';
import {
  createFolder,
  createLecturePage,
  fetchCollection,
  fetchFolder,
  fetchCollectionsList,
  updateFolder,
  getCollectionLecturePage,
  createCollection,
  syncLecturePage,
  editCollection, deleteCollection,
  setFolderExpandedStatus,
  deleteFolder,
  setHasMoreInCollectionSearch,
  setHighlightedLessonId,
  setHighlightedCollectionId,
  setNewFolderId,
  setScrollableCollectionLessonId,
  getCanConvertToCollectionLesson,
  getCanLinkCollectionLesson,
  unsetCollectionCanLinkAndConvert,
  reorderFolder,
  reorderLesson,
  canConvertToCollectionLecture,
} from 'redux/actions/collections';
import { LecturePageListSchema, LecturePageSchema } from 'redux/schemas/api/lecture-pages';
import { CollectionLecturePage, SyncingStatus } from 'redux/schemas/models/lecture-page';
import { initialRootState } from './index';

export default createReducer(initialRootState, builder => {
  builder
    .addCase(setFolderExpandedStatus, (state, action) => {
      state.app.collection.folder.expanded = {
        ...state.app.collection.folder.expanded,
        [action.payload.folderId]: action.payload.value,
      };
    })
    .addCase(setHasMoreInCollectionSearch, (state, action) => {
      state.app.collection.hasMoreInCollectionSearch = action.payload;
    })
    .addCase(setHighlightedLessonId, (state, action) => {
      state.app.collection.highlightedLessonId = action.payload;
    })
    .addCase(setHighlightedCollectionId, (state, action) => {
      state.app.collection.highlightedCollectionCatalogId = action.payload;
    })
    .addCase(setNewFolderId, (state, action) => {
      state.app.collection.newFolderId = action.payload;
    })
    .addCase(setScrollableCollectionLessonId, (state, action) => {
      state.app.collection.scrollableLessonId = action.payload;
    })
    .addCase(fetchCollectionsList.fulfilled, (state, action: any) => {
      if (action.payload.totalCount > 0) {
        state.app.collection.hasMoreInCollectionSearch = action.payload.totalCount > action.meta.arg.pageIndex * action.meta.arg.pageSize;
      }
      const data = normalize<CollectionCourse, CollectionEntities>(
        action.payload.response,
        CollectionCourseListSchema,
      );

      mergeWith(state.models, data.entities, replaceArrays);
    })
    .addCase(fetchCollection.fulfilled, (state, action: any) => {
      const data = normalize<CollectionCourse, CollectionEntities>(
        action.payload,
        CollectionCourseSchema,
      );

      mergeWith(state.models, data.entities, replaceArrays);
    })
    .addCase(createCollection.fulfilled, (state, action: any) => {
      const data = normalize<CollectionCourse, CollectionEntities>(
        action.payload,
        CollectionCourseSchema,
      );

      mergeWith(state.models, data.entities, replaceArrays);
    })
    .addCase(createFolder.fulfilled, (state, action: any) => {
      const data = normalize<CollectionCourse, CollectionEntities>(
        action.payload,
        CollectionFolderSchema,
      );
      const collection = state.models.courses[action.meta.arg.catalogId];
      const folderId = data.result;

      collection.foldersCount += 1;
      collection.folders.push(folderId);
      mergeWith(state.models, data.entities, replaceArrays);
      state.models.collectionFolders[folderId].lecturePagesCount = 0;

      // Setting created new folder in expanded state
      state.app.collection.folder.expanded[folderId] = true;
      state.app.collection.newFolderId = folderId;
    })
    .addCase(fetchFolder.fulfilled, (state, action: any) => {
      const data = normalize<CollectionCourse, CollectionEntities>(
        action.payload.response,
        LecturePageListSchema,
      );
      const folder = state.models.collectionFolders[action.meta.arg.folderId];

      folder.contentManagementLinksCount = action.payload.folder.contentManagementLinksCount;
      folder.lecturePagesCount = action.payload.folder.lecturePagesCount;
      folder.lecturePages = data.result;
      mergeWith(state.models, data.entities, replaceArrays);
    })
    .addCase(updateFolder.fulfilled, (state, action: any) => {
      const data = normalize<CollectionCourse, CollectionEntities>(
        action.payload,
        CollectionFolderSchema,
      );

      mergeWith(state.models, data.entities, replaceArrays);
    })
    .addCase(deleteFolder.fulfilled, (state, action: any) => {
      const { catalogId, folderId } = action.meta.arg;
      const collectionFolder = state.models.collectionFolders[folderId];
      const collection = state.models.courses[catalogId];

      delete state.models.collectionFolders[folderId];
      collection.foldersCount -= 1;
      collection.lecturePagesCount -= collectionFolder.lecturePagesCount;

      const folderIndex = collection.folders.indexOf(folderId);
      collection.folders.splice(folderIndex, 1);
    })
    .addCase(createLecturePage.fulfilled, (state, action: any) => {
      const data = normalize<CollectionLecturePage, CollectionLecturePagesNormalized>(
        action.payload,
        LecturePageSchema,
      );

      const collectionFolder = state.models.collectionFolders[action.meta.arg.folderId];
      collectionFolder.lecturePages = [...collectionFolder.lecturePages ?? [], data.result];
      mergeWith(state.models, data.entities, replaceArrays);
    })
    .addCase(getCollectionLecturePage.pending, (state, action) => {
      state.app.lecturePage.isCurrentLectureLoaded = false;
    })
    .addCase(getCollectionLecturePage.fulfilled, (state, action: any) => {
      const data = normalize(action.payload, LecturePageSchema);

      mergeWith(state.models, data.entities, replaceArrays);
      state.app.lecturePage.isCurrentLectureLoaded = true;
    })
    .addCase(syncLecturePage.pending, (state, action) => {
      state.models.lecturePages[action.meta.arg.lecturePageId].syncingStatus = SyncingStatus.SYNC_IN_PROGRESS;
    })
    .addCase(syncLecturePage.fulfilled, (state, action) => {
      state.models.lecturePages[action.meta.arg.lecturePageId].contentManagementLinks = action.payload.contentManagementLinks;
    })
    .addCase(editCollection.fulfilled, (state, action) => {
      const data = normalize<CollectionCourse, CollectionEntities>(
        action.payload,
        CollectionCourseSchema,
      );
      mergeWith(state.models, data.entities, replaceArrays);
    })
    .addCase(deleteCollection.fulfilled, (state, action) => {
      delete state.models.courses[action.meta.arg.catalogId];
    })
    .addCase(getCanLinkCollectionLesson.fulfilled, (state, action) => {
      state.app.collection.canLinkCollectionLesson = action.payload.result;
    })
    .addCase(getCanConvertToCollectionLesson.fulfilled, (state, action) => {
      state.app.collection.canConvertToCollectionLesson = action.payload.result;
    })
    .addCase(canConvertToCollectionLecture.fulfilled, (state, action) => {
      state.app.collection.canContentConvertToCollectionLesson = action.payload.result.canConvert;
    })
    .addCase(unsetCollectionCanLinkAndConvert, (state, action) => {
      state.app.collection.canLinkCollectionLesson = null;
      state.app.collection.canConvertToCollectionLesson = null;
      state.app.collection.canContentConvertToCollectionLesson = null;
    })
    .addCase(reorderFolder.fulfilled, (state, action) => {
      const collection = state.models.courses[action.meta.arg.catalogId];
      collection.folders = [...action.meta.arg.folders];
    })
    .addCase(reorderLesson.fulfilled, (state, action) => {
      const { destinationFolderId, sourceFolderId, lessonId, addedIndex, removedIndex } = action.meta.arg;
      state.models.collectionFolders[sourceFolderId].lecturePages.splice(removedIndex, 1);
      state.models.collectionFolders[sourceFolderId].lecturePagesCount -= 1;
      state.models.collectionFolders[destinationFolderId].lecturePages.splice(addedIndex, 0, lessonId);
      state.models.collectionFolders[destinationFolderId].lecturePagesCount += 1;
      if (!!state.models.lecturePages[lessonId].contentManagementLinks.length && destinationFolderId !== sourceFolderId) {
        state.models.collectionFolders[destinationFolderId].contentManagementLinksCount += 1;
        state.models.collectionFolders[sourceFolderId].contentManagementLinksCount -= 1;
      }
    });
});
