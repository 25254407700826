import React from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import {
  Popover,
  OverlayTrigger,
  OverlayTriggerProps,
} from 'react-bootstrap';

import t from 'react-translate';
import {
  Section,
  ColorValues,
  ChangeHandler,
  ColorPickerContent,
  ColorPaletteContent,
} from 'shared/components/inputs/color-picker';
import Divider from 'shared/components/divider';
import ClickableContainer, {
  ClickableContainerProps,
} from 'components/clickable-container';
import {
  ReactComponent as SimpleColorPickerIcon,
} from 'styles/icons/simple-color-picker.svg';
import {
  halfSpacing,
  doubleSpacing,
  quarterSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';
import useUserColors from 'shared/hooks/use-user-colors';
import { getCurrentLecture } from 'redux/selectors/lecture-page';
import maxSize from 'popper-max-size-modifier';
import { getApplyMaxSize } from 'shared/utils';

const DEFAULT_POPOVER_OFFSET = 10;

export type ColorValuesMap = ColorValues;

type OverlayTriggerInheritedOptionalProps = Partial<Pick<OverlayTriggerProps, 'placement' | 'container' | 'onToggle' | 'show'>>;

type OverlayTriggerInheritedProps = Required<Pick<OverlayTriggerProps, 'children'>> & OverlayTriggerInheritedOptionalProps;

export interface ColorPickerPopoverProps<ColorValuesMapT extends ColorValues> extends OverlayTriggerInheritedProps {
  offset?: number;
  colorValues: ColorValuesMapT;
  sections: Section<keyof ColorValuesMapT>[];
  /**
   * render prop to render something before the palette.
   */
  renderBeforePalette?(): React.ReactNode;
  onChange?: ChangeHandler<keyof ColorValuesMapT>;
}

const ColorPickerPopover = <ColorValuesMapT extends ColorValues>(
  props: ColorPickerPopoverProps<ColorValuesMapT>,
) => {
  const {
    offset,
    onChange,
    sections,
    children,
    onToggle,
    placement,
    container,
    colorValues,
    show: propsShow,
    renderBeforePalette,
  } = props;

  const [show, setShow] = React.useState(propsShow);
  const currentLecture = useSelector(getCurrentLecture);
  const [pickerVisible, setPickerVisible] = React.useState(false);
  const [selectedSection, setSelectedSection] = React.useState<keyof ColorValuesMapT>(null);

  const {
    updateRecentlyUsedColorsIfNeeded,
  } = useUserColors({
    currentLecture,
  });

  const handleToggle = (nextShow: boolean) => {
    onToggle?.(nextShow);
    setShow(nextShow);
  };

  const handlePickerChange = (hex) => {
    if (selectedSection) {
      onChange?.(selectedSection as string, hex);
    }
  };

  const updateRecentlyUsedColors = () => {
    if (selectedSection) {
      updateRecentlyUsedColorsIfNeeded(colorValues[selectedSection], true);
    }
  };

  const updateRecentlyUsedColorsRef = React.useRef(updateRecentlyUsedColors);
  updateRecentlyUsedColorsRef.current = updateRecentlyUsedColors;

  React.useEffect(() => {
    if (show) {
      setPickerVisible(false);
    } else {
      updateRecentlyUsedColorsRef.current();
    }
  }, [show]);

  React.useEffect(() => {
    setShow(propsShow);
  }, [propsShow]);

  React.useEffect(() => () => updateRecentlyUsedColorsRef.current(), []);

  const styles = css`
    .color-picker-content {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: inherit;
      padding: ${halfSpacing}px 0;
      border-radius: ${quarterSpacing}px;
    }

    .before-palette {
      padding: ${halfSpacing}px ${threeQuartersSpacing}px;
    }
  `;

  const overlay = (
    <Popover id='color-picker' css={styles}>
      {pickerVisible ? (
        <ColorPickerContent
          onChange={handlePickerChange}
          className='color-picker-content'
          color={colorValues?.[selectedSection]}
        />
      ) : (
        <div className='color-picker-content'>
          {renderBeforePalette && (
            <div className='before-palette'>
              {renderBeforePalette()}
            </div>
          )}
          {renderBeforePalette && <Divider />}
          <ColorPaletteContent<ColorValuesMapT>
            sections={sections}
            onChange={onChange}
            colorValues={colorValues}
            onColorPickerClick={(sectionName: string) => {
              setSelectedSection(sectionName);
              setPickerVisible(true);
            }}
          />
        </div>
      )}
    </Popover>
  );

  return (
    <OverlayTrigger
      rootClose
      show={show}
      trigger='click'
      overlay={overlay}
      container={container}
      onToggle={handleToggle}
      placement={placement || 'top'}
      popperConfig={{
        modifiers: [
          {
            name: 'flip',
            enabled: true,
          },
          {
            name: 'offset',
            options: {
              offset: () => [0, offset ?? DEFAULT_POPOVER_OFFSET],
            },
          },
          maxSize,
          getApplyMaxSize(),
        ],
      }}
    >
      {children}
    </OverlayTrigger>
  );
};

export const SetBackgroundColorTarget = (props: ClickableContainerProps) => {
  const { className, ...restProps } = props;

  const styles = css`
    height: ${doubleSpacing}px;

    .text-small {
      flex-shrink: 0;
      color: inherit;
      white-space: nowrap;
    }
  `;

  return (
    <ClickableContainer
      css={styles}
      className={`d-flex pl-2 pr-2 align-items-center ${className ?? ''}`}
      {...restProps}
    >
      <SimpleColorPickerIcon
        width={16}
        height={16}
        className='mr-2'
      />
      <div className='text-small'>{t.SHARED.SET_BACKGROUND_COLOR()}</div>
    </ClickableContainer>
  );
};

export default ColorPickerPopover;
