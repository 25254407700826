import React from 'react';
import omit from 'lodash/omit';
import { css } from '@emotion/react';
import { OverlayTrigger } from 'react-bootstrap';
import { Offset } from 'react-overlays/usePopper';
import { Placement } from 'react-bootstrap/Overlay';

import t from 'react-translate';
import { mergeRefs } from 'shared/react-utils';
import usePrevious from 'shared/hooks/use-previous';
import ClickableContainer from 'components/clickable-container';
import useDebouncedEffect from 'shared/hooks/use-debounced-effect';
import { black, gray5, hexToRgbaString } from 'styles/global_defaults/colors';
import {
  dropdownZIndex,
  quarterSpacing,
} from 'styles/global_defaults/scaffolding';

const verticalArrowKeyNames = {
  ArrowUp: -1,
  ArrowDown: 1,
};

const styles = css`
  position: relative;
`;

type RenderProp<Params extends any[]> = (...params: Params) => React.ReactNode;

type NativeInputProps = React.ComponentProps<'input'>;

type InputProps = {
  value: string,
  ref: React.Ref<HTMLInputElement>,
  onBlur: NativeInputProps['onBlur'],
  onFocus: NativeInputProps['onFocus'],
  onChange: NativeInputProps['onChange'],
};

type ResultItemProps = {
  tabIndex: number,
  ref: React.Ref<any>,
  key: string | number,
  onBlur: (event: React.FocusEvent) => void,
  onClick: (event: React.MouseEvent) => void,
  onMouseDown: (event: React.MouseEvent) => void,
};

type ValueSelectedProps = {
  onClick: () => void,
  ref: React.Ref<any>,
};

type Props<T> = {
  // Results related props
  fetchPredictions: (hint: string) => (Promise<T[]> | T[]),
  keyExtractor?: (result: T, index: number) => string | number,
  // Controlling props
  value?: T,
  onChange?: (newValue: T) => void,
  // Styling props
  className?: string,
  resultsContainerClassName?: string,
  // Render props
  renderNoResults?: RenderProp<[]>,
  renderLoadingIndicator?: RenderProp<[]>,
  renderInput?: RenderProp<[InputProps]>,
  renderResultItem?: RenderProp<[ResultItemProps, T]>,
  renderValueSelected?: RenderProp<[ValueSelectedProps, T]>,
  // Config props
  offset?: Offset,
  debounce?: number,
  placement?: Placement,
  cleanHintOnSelect?: boolean,
};


/**
 * NvAutocompleteBase is a component that allows you to easily build an
 * autocomplete component, only caring about the relevant things and letting it
 * do the rest for you.
 */
const NvAutocompleteBase = <ResultType extends unknown>(props: Props<ResultType>) => {
  const defaultRenderItem = (itemProps, value) => <ClickableContainer {...itemProps}>{value}</ClickableContainer>;

  const {
    offset,
    onChange,
    className,
    debounce = 250,
    fetchPredictions,
    value: propsValue,
    placement = 'bottom',
    resultsContainerClassName,
    cleanHintOnSelect = false,
    renderResultItem = defaultRenderItem,
    keyExtractor = (result, index) => index,
    renderValueSelected = defaultRenderItem,
    renderInput = (inputProps) => <input {...inputProps} />,
    renderNoResults = () => t.SEARCH.NO_RESULTS_FOUND(),
    renderLoadingIndicator = () => t.SEARCH.LOADING_RESULTS(),
  } = props;

  const mountedRef = React.useRef(false);
  const hintRef = React.useRef<string>();
  const previousValueRef = React.useRef();
  const [hint, setHint] = React.useState('');
  const resultsElementsRef = React.useRef({});
  const [value, setValue] = React.useState(null);
  const previousValue = usePrevious(value);
  const internallyValueSetRef = React.useRef(false);
  const containerRef = React.useRef<HTMLDivElement>();
  const currentFocusedResultIndexRef = React.useRef(null);
  const searchInputRef = React.useRef<HTMLInputElement>();
  const resultsContainerRef = React.useRef<HTMLDivElement>();
  const [isSearching, setIsSearching] = React.useState(false);
  const wasResultSelectedWithKeyboardRef = React.useRef(false);
  const currentResultElementRef = React.useRef<HTMLButtonElement>();
  const [isInputFocused, setIsInputFocused] = React.useState(false);
  const [finishedDebounce, setFinishedDebounce] = React.useState(false);
  const [results, setResults] = React.useState<ResultType[] | null>(null);
  const inputBlurRelatedTargetRef = React.useRef<HTMLElement>(null);

  hintRef.current = hint;
  previousValueRef.current = previousValue;

  const isResultsOverlayOpen = (() => {
    if (isInputFocused && (debounce ? finishedDebounce : true)) {
      if (results) {
        return true;
      }

      return isSearching;
    }

    return false;
  })();

  const setActualValue = (newValue: ResultType) => {
    internallyValueSetRef.current = true;
    onChange?.(newValue);

    if (propsValue === undefined) {
      setValue(newValue);
    }
  };


  const focusInput = () => searchInputRef.current.focus();

  const resetValue = () => setActualValue(null);

  React.useLayoutEffect(() => {
    if (propsValue !== undefined) {
      setValue(propsValue);
    }
  }, [propsValue]);

  React.useLayoutEffect(() => {
    if (value === null) {
      if (!internallyValueSetRef.current) {
        setHint('');
      } else if (mountedRef.current && previousValueRef.current) {
        focusInput();
      }
    } else if (value && wasResultSelectedWithKeyboardRef.current) {
      currentResultElementRef.current.focus();
    }

    internallyValueSetRef.current = false;
  }, [value]);

  React.useEffect(() => {
    if (!isResultsOverlayOpen) {
      currentFocusedResultIndexRef.current = null;
    }
  }, [isResultsOverlayOpen]);

  useDebouncedEffect(() => {
    setFinishedDebounce(true);

    const result = fetchPredictions(hint);
    if (result instanceof Promise) {
      setIsSearching(true);
      result.then((predictions) => {
        setIsSearching(false);
        setResults(predictions);
      });
    } else {
      setResults(result);
    }
  }, debounce, [hint]);

  React.useEffect(() => {
    mountedRef.current = true;
  });

  const resultsContainerStyles = css`
    width: 100%;
    overflow-y: auto;
    border-radius: 4px;
    border: 1px solid ${gray5};
    z-index: ${dropdownZIndex};
    box-shadow: 0px ${quarterSpacing}px ${quarterSpacing}px 0px ${hexToRgbaString(black, 0.1)};
  `;

  const renderResults = () => (results?.length ? results.map((currentResult, index) => {
    const handleResultItemClick = (event) => {
      setActualValue(currentResult);
      setIsInputFocused(false);

      if (event.detail === 0) {
        wasResultSelectedWithKeyboardRef.current = true;
      } else {
        wasResultSelectedWithKeyboardRef.current = false;
      }

      if (cleanHintOnSelect) {
        setHint('');
      }
    };

    const handleResultItemMouseDown = (e) => {
      inputBlurRelatedTargetRef.current = e.currentTarget;
    };

    const handleResultItemBlur = (event) => {
      if (!resultsContainerRef.current.contains(event.relatedTarget)) {
        setIsInputFocused(false);
      }
    };

    return renderResultItem({
      tabIndex: -1,
      onBlur: handleResultItemBlur,
      onClick: handleResultItemClick,
      onMouseDown: handleResultItemMouseDown,
      key: keyExtractor(currentResult, index),
      ref: (ref) => { resultsElementsRef.current[index] = ref; },
    }, currentResult);
  }) : renderNoResults());

  const handleContainerKeyDown = (event) => {
    if (Object.prototype.hasOwnProperty.apply(verticalArrowKeyNames, [event.key]) && isResultsOverlayOpen && results?.length) {
      event.preventDefault();
      const delta = verticalArrowKeyNames[event.key];

      let newCurrentFocusedResultIndex;

      if (currentFocusedResultIndexRef.current === null) {
        if (delta === 1) {
          newCurrentFocusedResultIndex = 0;
        } else {
          newCurrentFocusedResultIndex = results.length - 1;
        }
      } else {
        newCurrentFocusedResultIndex = currentFocusedResultIndexRef.current + delta;
      }

      if (newCurrentFocusedResultIndex <= -1 || (newCurrentFocusedResultIndex >= results?.length ?? 0)) {
        return;
      }

      const elementToFocus = resultsElementsRef.current[newCurrentFocusedResultIndex];
      inputBlurRelatedTargetRef.current = elementToFocus;
      elementToFocus.focus();

      currentFocusedResultIndexRef.current = newCurrentFocusedResultIndex;
    } else if (event.key === 'Escape' && isResultsOverlayOpen && resultsContainerRef.current.contains(event.target)) {
      focusInput();
      currentFocusedResultIndexRef.current = null;
    }
  };

  const handleOverlayMouseDown = (e) => {
    inputBlurRelatedTargetRef.current = e.currentTarget;
  };

  const handleInputChange = (event) => {
    const { value: newValue } = event.target;

    setHint(newValue);
  };

  const handleInputFocus = () => setIsInputFocused(true);

  // eslint-disable-next-line consistent-return
  const handleInputBlur = () => {
    const relatedTarget = inputBlurRelatedTargetRef.current;

    if (!relatedTarget) {
      return setIsInputFocused(false);
    }

    inputBlurRelatedTargetRef.current = null;
  };

  const handleToggleClick = () => resetValue();

  return (
    <div
      css={styles}
      ref={containerRef}
      className={className}
      onKeyDown={handleContainerKeyDown}
    >
      {value ? renderValueSelected({
        onClick: handleToggleClick,
        ref: currentResultElementRef,
      }, value) : (
        <OverlayTrigger
          transition={false}
          placement={placement}
          show={isResultsOverlayOpen}
          container={containerRef.current}
          popperConfig={{
            modifiers: [offset && {
              name: 'offset',
              options: {
                offset: () => offset,
              },
            }].filter(Boolean),
          }}
          overlay={({ ref, ...overlayProps }) => (
            <div
              css={resultsContainerStyles}
              onMouseDown={handleOverlayMouseDown}
              className={resultsContainerClassName}
              ref={mergeRefs(ref, resultsContainerRef)}
              {...omit(overlayProps, ['show', 'popper', 'arrowProps'])}
            >
              {isSearching ? renderLoadingIndicator() : renderResults()}
            </div>
          )}
        >
          {(data) => (
            renderInput({
              value: hint,
              onBlur: handleInputBlur,
              onFocus: handleInputFocus,
              onChange: handleInputChange,
              ref: mergeRefs(data.ref, searchInputRef),
            })
          )}
        </OverlayTrigger>
      )}
    </div>
  );
};

/**
 * useLoadingText is a NvAutocompletebase static hook that allows you to
 * display an animated text (adds and removes dots at the end to indicate a
 * process is occurring) with the loading text specified as parameter.
 */
const useLoadingText = (loadingText: string) => {
  const timeoutRef = React.useRef<any>();
  const [dots, setDots] = React.useState<number>(0);

  React.useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      if (dots < 3) {
        setDots(dots + 1);
      } else {
        setDots(0);
      }
    }, 250);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [dots]);
  const finalText = `${loadingText}${Array(dots).fill('.').join('')}`;

  return finalText;
};

NvAutocompleteBase.useLoadingText = useLoadingText;

export default NvAutocompleteBase;
