/* @ngInject */
export function nvValidationPopover(
  $compile,
  $interpolate,
) {
  return {
    restrict: 'A',
    terminal: true,
    priority: 1000,
    link: function link(scope, element, attrs) {
      // var isOpenString = attrs.nvValidationPopover + '.$showValidationMessage &&' + attrs.nvValidationPopover + '.$invalid';


      let isOpenString = 'ngModelCtrl.$showValidationMessage && ngModelCtrl.$invalid'.replace(/ngModelCtrl/g, attrs.nvValidationPopover);

      if (Object.prototype.hasOwnProperty.call(attrs, 'ngfDrop') || Object.prototype.hasOwnProperty.call(attrs, 'ngfSelect')) {
        isOpenString = `${attrs.nvValidationPopover}.$errorParam && ${attrs.nvValidationPopover}.$error`;
      }


      element.attr('uib-popover-template', '"shared/templates/nv-validation-popover-body.html"');
      element.attr('popover-title', '{{ "FORM.WARNING" | translate }}');
      element.attr('popover-class', 'validation');
      element.attr('popover-trigger', 'none');
      element.attr('popover-is-open', isOpenString);
      element.attr('popover-placement', attrs.popoverPlacement || 'top auto');

      element.attr('input-name', attrs.nvValidationPopover);
      element.attr('validate-on-blur', 'true');

      // cleanup existing instance to prevent multiple compilations
      element.removeAttr('nv-validation-popover');
      element.removeAttr('data-nv-validation-popover');

      $compile(element)(scope);
    },
  };
}

/* @ngInject */
export function nvValidationPopoverBody($compile, $interpolate, $parse, _) {
  return {
    link: function link(scope, element, attrs) {
      const $input = element.closest('.popover').prev();
      scope.ngModelCtrl = $parse($input.attr('input-name'))(scope);
      scope._ = _;
      scope.attrs = {
        ngMinlength: $input.attr('ng-minlength'),
        ngMaxlength: $input.attr('ng-maxlength') || $input.attr('nv-max-length'),
        min: $input.attr('min') || $input.attr('ng-min'),
        max: $input.attr('max') || $input.attr('ng-max'),
        maxSize: $input.attr('maxSize'),
        patternValidationKey: $input.attr('patternValidationKey'),
        patternValidationValues: $input.attr('patternValidationValues'),
        customValidationKey: $input.attr('custom-validation-key'),
        customValidationValues: $input.attr('custom-validation-key-values'),
        matchValidationKey: $input.attr('matchValidationKey'),
      };
    },
  };
}

/* @ngInject */
export function validateOnBlur(
  _,
  $timeout,
  $parse,
) {
  return {
    require: 'ngModel',
    link(scope, element, attrs, ngModelCtrl) {
      // once you clear the input, it is no longer considered to be dirty until the next blur
      let novoedDirty = false;
      // If the input is required, and we want validation popover to show up
      const showRequiredPopover = $parse(attrs.showRequiredPopover)(scope);

      let eventName = 'blur';

      if (_.has(attrs, 'nvFroalaEditor')) {
        eventName = 'froalaEditor.blur';
      }

      if (!(Object.prototype.hasOwnProperty.call(attrs, 'ngfDrop') || Object.prototype.hasOwnProperty.call(attrs, 'ngfSelect'))) {
        element.on(eventName, () => {
          $timeout(() => {
            if (showRequiredPopover) {
              novoedDirty = true;
            } else if (!ngModelCtrl.$isEmpty(ngModelCtrl.$viewValue)) {
              novoedDirty = true;
            }

            setValidationShow(true);
          });
        });

        element.on('keydown', () => {
          $timeout(() => {
            if (ngModelCtrl.$isEmpty(ngModelCtrl.$viewValue) && !showRequiredPopover) {
              novoedDirty = false;
            }

            setValidationShow(false);
          });
        });
      }

      function setValidationShow(ignoreTouched) {
        ngModelCtrl.$showValidationMessage = novoedDirty && ngModelCtrl.$invalid && (ignoreTouched || ngModelCtrl.$touched);
      }
    },
  };
}

/* @ngInject */
export function nvValidateOtherOnBlur(
  $timeout,
) {
  return {
    link(scope, element, attrs) {
      // once you clear the input, it is no longer considered to be dirty until the next blur
      let novoedDirty = false;

      if (!(Object.prototype.hasOwnProperty.call(attrs, 'ngfDrop') || Object.prototype.hasOwnProperty.call(attrs, 'ngfSelect'))) {
        element.on('blur', () => {
          const targetModelCtrl = scope.$eval(attrs.nvValidateOtherOnBlur);
          if (!targetModelCtrl.$isEmpty(targetModelCtrl.$viewValue)) {
            novoedDirty = true;
          }

          setValidationShow();
        });

        element.on('keyup', () => {
          const targetModelCtrl = scope.$eval(attrs.nvValidateOtherOnBlur);
          if (targetModelCtrl.$isEmpty(targetModelCtrl.$viewValue)) {
            novoedDirty = false;
          }

          setValidationShow();
        });
      }

      function setValidationShow(ignoreTouched) {
        $timeout(() => {
          const targetModelCtrl = scope.$eval(attrs.nvValidateOtherOnBlur);
          targetModelCtrl.$showValidationMessage = novoedDirty && targetModelCtrl.$invalid && (ignoreTouched || targetModelCtrl.$touched);
        });
      }
    },
  };
}
