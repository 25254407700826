import React, { forwardRef, MutableRefObject, useEffect, useRef } from 'react';
import { Chart as ChartJS, ChartData, ChartOptions, ChartType,
  LineElement,
  LineController,
  LinearScale,
  Legend,
  Title,
  Tooltip,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import merge from 'lodash/merge';

ChartJS.register(
  LineElement,
  LineController,
  LinearScale,
  Legend,
  Title,
  Tooltip,
  annotationPlugin,
);
interface ChartProps {
  type: ChartType
  data: ChartData
  options?: ChartOptions
}

const defaultOptions: ChartOptions = {
  responsive: true,
};

type ForwardedRef<T> =
  | ((instance: T | null) => void)
  | MutableRefObject<T | null>
  | null;

function reforwardRef<T>(ref: ForwardedRef<T>, value: T) {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
}

const NvChart = forwardRef(({
  type,
  data,
  options,
}: ChartProps, ref: ForwardedRef<any>) => {
  const chartRef = useRef(null);

  useEffect(() => {
    buildChart();
    return () => destroyChart();
  }, []);

  const buildChart = () => {
    const NvChartRef = chartRef.current.getContext('2d');

    reforwardRef(ref, chartRef.current);

    chartRef.current = new ChartJS(NvChartRef, {
      type,
      data,
      options: merge(defaultOptions, options),
    });
  };

  const destroyChart = () => {
    reforwardRef(ref, null);

    if (chartRef.current) {
      chartRef.current.destroy();
      chartRef.current = null;
    }
  };

  return (
    <div>
      <canvas
        id='nvChart'
        ref={chartRef}
      />
    </div>
  );
});

export default NvChart;
