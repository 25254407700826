import { css } from '@emotion/react';
import moment from 'moment';
import NvIcon from 'shared/components/nv-icon';

import { useRef, useContext, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { each, isEmpty } from 'underscore';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { ConfirmationDialog } from 'redux/schemas/app/confirmation-dialog';
import { getFlatCourseAliases } from 'redux/selectors/course';
import { getDeadline } from 'redux/selectors/lecture-components';
import { useAppDispatch } from 'redux/store';
import NvDatePicker, { DatePickerType } from 'shared/components/inputs/nv-datepicker';
import NvTooltip from 'shared/components/nv-tooltip';
import useClickOutside from 'shared/hooks/use-click-outside';
import NvPopover from 'shared/components/nv-popover';
import { AngularServicesContext } from 'react-app';
import ValidationErrorMessage from 'shared/components/inputs/validation-error-message';
import ClickableContainer from 'components/clickable-container';
import { isRtl } from 'styles/global_defaults/media-queries';
import { mergeWith } from 'lodash';
import { replaceArrays } from 'shared/lodash-utils';
import { updateLecturePageReleaseDate } from 'redux/actions/lecture-pages';
import { useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import { LecturePageMode } from '..';
import { LecturePageEditContentParams } from '../lecture-page-content';

type LectureContentReleaseDateProps = {
} & LecturePageEditContentParams;

export const LectureContentReleaseDate = (props: LectureContentReleaseDateProps) => {
  const styles = css`
    .date-button {
      cursor: pointer;
    }
  `;

  const dispatch = useAppDispatch();
  const { $timeout, $scope } = useContext(AngularServicesContext);
  const [releaseDateDraft, setReleaseDateDraft] = useState(moment(props.lecturePage.releaseDate));
  const canEdit = props.mode === LecturePageMode.EDIT || props.mode === LecturePageMode.LINKED_EDIT;
  const [isEditing, setIsEditing] = useState(false);
  const datePickerRef = useRef(null);
  const courseAliases = useSelector((state) => getFlatCourseAliases(state, props.catalogId));
  const params = useLecturePageParams();

  const isLectureReleased = (newDate: moment.MomentInput) => moment(newDate) < moment();

  // Warning for when a deadline exists at a date earlier than the new release date
  // TODO: Port https://github.com/novoed/NovoEdWeb/blob/f6aaa8b4b400696997594a5e1dd698610acb8526/app/lecture_pages/services/lecture-page-model.js#L438
  // (check if any lcs have a `deadline` prop < the new date)

  const lectureComponentDeadlines = useSelector(state => (props.lecturePage.lectureComponents
    ? props.lecturePage.lectureComponents.map(lcId => getDeadline(state, lcId)).filter(dl => dl)
    : []));
  const [hasDateConflict, setHasDateConflict] = useState(false);

  /** Show a confirmation dialog before changing the release date if some circumstances are met */
  const showConfirmationIfNeeded = (
    newDate: moment.Moment,
    onConfirm: ConfirmationDialog['onConfirm'],
    onCancel?: ConfirmationDialog['onCancel'],
  ) => {
    // Warning for when a released course is becoming unreleased
    const isUnreleasing = !isLectureReleased(newDate) && isLectureReleased(props.lecturePage.releaseDate);

    // Warning for when communications exist and this new date is earlier than previously set
    const communicationsExist = props.lecturePage.communicationsCount > 0 && moment(newDate) < moment(props.lecturePage.releaseDate);

    // If no issues found, allow the date change to occur as normal
    if (!isUnreleasing && !hasDateConflict && !communicationsExist) {
      onConfirm();
      return;
    }

    // Otherwise custom-tailor the title/body text based on which combination of warnings should be shown
    let title: string = null;

    if (isUnreleasing) {
      title = t.LECTURE_PAGES.UNRELEASE_TITLE(courseAliases);
    } else if (hasDateConflict) {
      title = t.LECTURE_PAGES.DATE_CONFLICT_TITLE();
    }

    let body: string = null;

    if (isUnreleasing && !hasDateConflict) {
      body = t.LECTURE_PAGES.UNRELEASE(courseAliases);
    } else if (!isUnreleasing && hasDateConflict) {
      body = t.LECTURE_PAGES.DATE_CONFLICT();
    } else if (isUnreleasing && hasDateConflict) {
      body = t.LECTURE_PAGES.UNRELEASE_DATE_CONFLICT(courseAliases);
    }

    if (communicationsExist) {
      body += t.LECTURE_PAGES.COMMUNICATION_EXIST(courseAliases);
    }

    dispatch(openConfirmationDialog({
      title,
      bodyText: body,
      onConfirm,
      confirmText: t.DASHBOARD.CONFIRMATION.YES_SURE(),
      onCancel,
    }));
  };

  // Fires saving the new date when clicking outside the date input & popover
  useClickOutside(datePickerRef, () => {
    if (!isEmpty(releaseDateDraft)) {
      const newDate = moment(releaseDateDraft);

      if (!newDate.isSame(moment(props.lecturePage.releaseDate))) {
        showConfirmationIfNeeded(newDate, () => {
          $timeout(() => {
            dispatch(updateLecturePageReleaseDate({
              ...params,
              releaseDate: newDate.toISOString(),
            })).then((response) => {
              /**
               * Some components use the lecture page's release date checks within
               * Angular components, yet they fail to account for updated values
               * when modifying the date. Therefore, updating the Angular lecture
               * component scope with the updated lecture page data here.
               */
              const updatedLecturepage = response.payload;
              each(props.lecturePage.lectureComponents, (lcId => {
                const angularComponentId = `lectureComponent${lcId}`;
                if (!isEmpty($scope[angularComponentId]?.lecturePage)) {
                  $timeout(() => {
                    mergeWith($scope[angularComponentId].lecturePage, updatedLecturepage, replaceArrays);
                  });
                }
              }));
            });
          });
        }, () => {
          setReleaseDateDraft(moment(props.lecturePage.releaseDate));
        });
      }

      setIsEditing(false);
    }
  }, [releaseDateDraft]);

  const calculateIfReleaseDateConflict = useCallback((newReleaseDate: moment.Moment) => {
    // Note the use of some() here: this short circuits unlike a .forEach()
    const hasConflict = lectureComponentDeadlines.some(deadline => moment(deadline) < newReleaseDate);
    setHasDateConflict(hasConflict);
  }, [lectureComponentDeadlines]);

  const onDateChanged = (val: moment.Moment) => {
    setReleaseDateDraft(val);
  };

  useEffect(() => {
    calculateIfReleaseDateConflict(releaseDateDraft);
  }, [calculateIfReleaseDateConflict, lectureComponentDeadlines, releaseDateDraft]);

  useEffect(() => {
    setReleaseDateDraft(moment(props.lecturePage.releaseDate));
  }, [props.lecturePage.releaseDate]);

  return (
    <div className='d-flex align-items-center'>
      {hasDateConflict && canEdit && (
        <NvPopover
          content={(
            <ValidationErrorMessage
              text={t.LECTURE_PAGES.RELEASE_DEADLINE_CONFLICT(courseAliases)}
              textFontClassName='text-medium'
            />
          )}
          showOnHover
          placement='bottom'
        >
          <NvIcon icon='warning' size='smallest' className='text-danger' />
        </NvPopover>
      )}
      <div css={styles} className='pl-2 ml-auto'>
        {!isEditing && (
          <ClickableContainer
            className={`${canEdit ? 'date-button' : ''} text-small font-weight-bold`}
            onClick={() => canEdit && setIsEditing(true)}
            data-qa='lecture-page-release-date-edit-button'
          >
            <div>
              <NvTooltip text={t.LECTURE_PAGES.UPDATE_RELEASE_DATE()} enabled={canEdit}>
                <div>
                  {props.lecturePage.released
                    && t.LECTURE_PAGES.PAST_RELEASE(moment(releaseDateDraft).format('L'))}
                  {!props.lecturePage.released
                    && t.LECTURE_PAGES.FUTURE_RELEASE(moment(releaseDateDraft).format('L'))}
                </div>
              </NvTooltip>
            </div>
          </ClickableContainer>
        )}
        <NvPopover
          show={isEmpty(releaseDateDraft)}
          placement='left'
          content={(
            <ValidationErrorMessage
              title={t.FORM.WARNING()}
              text={t.VALIDATION.REQUIRED()}
            />
          )}
        >
          {isEditing && (
            <div ref={datePickerRef}>
              <NvDatePicker
                value={!isEmpty(releaseDateDraft) ? moment(releaseDateDraft) : null}
                type={DatePickerType.DATETIME}
                placement={isRtl() ? 'bottom-start' : 'bottom-end'}
                onChange={(val) => onDateChanged(val)}
                autoFocus
                highlightDates={[moment()]}
                dataQa='lecture-page-release-date-input'
              />
            </div>
          )}
        </NvPopover>
      </div>
    </div>
  );
};

export default LectureContentReleaseDate;
