import { css } from '@emotion/react';
import t from 'react-translate';

type ValidationProps = {
  text: string,
  title?: string,
  textFontClassName?: string,
};

const ValidationErrorMessage = (props: ValidationProps) => (
  <div className={`flex-column ${props.textFontClassName}`}>
    <div className={`mx-auto text-danger text-center ${props.textFontClassName ?? 'font-weight-bold'}`}>
      {props.title ?? t.FORM.WARNING()}
    </div>
    <div className='mt-2'>{props.text}</div>
  </div>
);

export default ValidationErrorMessage;
