import { css } from '@emotion/react';
import React, { useEffect, useRef } from 'react';
import Button from 'react-bootstrap/esm/Button';
import { danger } from 'styles/global_defaults/colors';
import { semiBoldFontWeight } from 'styles/global_defaults/fonts';

type ConfirmationProps ={
  onCancel: () => void;
  onConfirm: () => void;
  header: string;
  message: string;
  cancelBtnText: string;
  confirmBtnText: string;
  pendoTagName?: string;
};

const styles = css`
  &.confirmation{
    max-width: 170px;
    // min-width is set to have always a space between option buttons
    min-width: 160px;
    .confirmation-heading{
      text-align:center;
      font-weight:${semiBoldFontWeight};
    }
    .confirmation-text{

    }
    .button-container{
      display:flex;
      justify-content:space-around;
      .cancel-button{
        color:${danger};
      }
    }
  }
`;

const NvConfirmationPopover = (props: ConfirmationProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    buttonRef.current?.focus();
  }, []);
  return (
    <div css={styles} className='confirmation'>
      <div className='confirmation-heading text-danger mb-2'>{props.header}</div>
      <span className='confirmation-text'>{props.message}</span>
      <div className='button-container mt-4 ml-2 mr-2'>
        <Button ref={buttonRef} className='bg-white border-danger cancel-button mr-2' variant='secondary' size='sm' onClick={props.onCancel}>{props.cancelBtnText}</Button>
        <Button variant='danger' size='sm' onClick={props.onConfirm} pendo-tag-name={props.pendoTagName}>{props.confirmBtnText}</Button>
      </div>
    </div>
  );
};

export default NvConfirmationPopover;
